.link {
  color: initial;
  text-decoration: none;

  &:visited {
    color: initial;
  }

  &:active {
    color: initial;
  }
}