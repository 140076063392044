.analyticsCard{
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 0;
    overflow-wrap: break-word;
    background-color: rgb(255, 255, 255);
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
    box-shadow:
            rgb(0 0 0 / 10%) 0 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0 0.125rem 0.25rem -0.0625rem;
}

.iconContainer{
    color:white;
    box-shadow: rgb(0 0 0 / 14%) 0 0.25rem 1.25rem 0, rgb(64 64 64 / 40%) 0 0.4375rem 0.625rem -0.3125rem;
    border-radius:0.75rem;
    display:flex;
    justify-content:center;
    align-items:center;
    width:4rem;
    height:4rem;
    margin-top: -24px;
    margin-right: 16px;
}