:root {
  --black-000: #202020;
  --gray-100: #3D3D3D; 
}


.form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
  }
    
  .form__label {
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px
  }
  
  .form__label::before {
    content: attr(data-title);
    font-size: 1rem;
    font-weight: 600;
  }
  
  .form__label input {
    width: 100%;
    max-width: 500px;
    height: 3rem;
    font-weight: 500;
    padding: 0 1rem;
    border-radius: 0.5rem;
    border: 0.063rem solid var(--gray-100);
    transition: border-color 0.3s;
  }
  
  .form__label input:focus {
    border-color: var(--black-000);
  }

  .form__item{
    display: flex;
    justify-content: center;
  }
  